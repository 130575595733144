<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Form validation component
 */


export default {
  page: {
    title: "Blog",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ckeditor: CKEditor.component, },





  data() {
    return {
      title: "Blog",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      editor: ClassicEditor,
      blogObj: {
        title: "",
        content: "",
        file: "",
        description: "",
        lang: "",
      },

      blogObjModif: {
        title: "",
        content: "",
        image: "",
        description: "",
        lang: "",

      },
      blogRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      bonPlan: [],
      blogActif: [],
      blogDes: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "author", sortable: true, label: "Auteur" },
        { key: "title", sortable: true, label: "Titre" },
        { key: "datePublish", sortable: true, label: "Date de création" },



        { key: "action" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      showSave: false,
      totalblog: 0,
      totalblogInactif: 0,
      totalblogActif: 0,
      showEditValidation: false
    };
  },


  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const listblog = await apiRequest("GET", "articles-list", undefined, false);

      if (listblog && listblog.data) {
       

        const blogTAble = listblog.data.map((blog) => {
          return {
            title: blog.title,
            id: blog.id,

            visible: blog.visible,
            description: blog.description,
            content: blog.content,
            lang: blog.lang,

            author: `${blog.auteur.nom} ${blog.auteur.prenom}`,

            datePublish: new Date(blog.datePublish).toLocaleString("fr-FR"),

          };
        });
        this.blog = blogTAble;

       
        this.blogActif = [];
        this.blogDes = [];

        for (let i = 0; i < this.blog.length; i++) {
          if (this.blog[i].visible === true) {
            this.blogActif.push(this.blog[i]);
            this.totalblogActif = this.blogActif.length
          } else {
            this.blogDes.push(this.blog[i]);
            this.totalblogInactif = this.blogDes.length;
           
          }
        }
      }
    },
    editRow(row) {
      this.showEdit = true;

      this.blogObjModif.title = row.title;

      this.blogObjModif.content = row.content;
      this.blogObjModif.id = row.id;
      this.blogObjModif.file = row.imageblogCode;
      this.blogObjModif.description = row.description;
      this.blogObjModif.lang = row.lang;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.blogRowInfo = row;
    },
    activerblog(row) {
      this.showactive = true;
      this.blogRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.blogRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    emptiedInput() {
      this.this.blogObj.title = ""
      this.this.blogObj.content = ""
      this.this.blogObj.descroiption = ""
      this.this.blogObj.lang = "Français"
      this.file = null
    },

    async submitblogObj() {
      let formData = new FormData();
      formData.append("title", this.blogObj.title);

      formData.append("content", this.blogObj.content);
      formData.append("lang", this.blogObj.lang);
      formData.append("description", this.blogObj.description);
      formData.append("image", this.file);
      const returnblog = await apiRequest("POST", "articles-publish", formData, true);

      if (returnblog && returnblog.data) {
        this.$toast.success("Information enregistrée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.showDelete = false;
        this.init();

        this.emptiedInput();
      } else {
        this.init();
        this.$toast.error(
          "Information non enregistrée;Veuillez réessayer",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
      this.showDelete = false;
    },
    async submitblogObjModif() {
      let formData = new FormData();
      formData.append("id", this.blogObjModif.id);
      formData.append("title", this.blogObjModif.title);
      formData.append("content", this.blogObjModif.content);
      formData.append("description", this.blogObjModif.description);
      formData.append("lang", this.blogObjModif.lang);



      const returnblogModif = await apiRequest("PUT", "articles-update ", formData, true);


      if (returnblogModif && returnblogModif.data) {
        this.showEditValidation = false;
        this.init();

        this.$toast.success("Article modifié avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });


      } else {
        this.init();
        this.$toast.error(
          "Article non modifié. Veuillez réessayer",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
    },
    async desactivePlan() {
      this.showdisable = false;
      const returnActualitDeactivate = await apiRequest(
        "POST",
        "articles-action",
        { id: this.blogRowInfo.id, action: "DISABLE" },
        false
      );

      if (returnActualitDeactivate && returnActualitDeactivate.data) {
        this.showdisable = false;
        this.init();

        this.$toast.success("Information désactivée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

      }
    },
    async activePlan() {

      const returnActualitActive = await apiRequest(
        "POST",
        "articles-action",
        { id: this.blogRowInfo.id, action: "ABLE" },
        false
      );

      if (returnActualitActive && returnActualitActive.data) {
        this.showactive = false;
        this.init();
        this.$toast.success("Information activée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

      }
    },
    async deletePlan() {
      const returnActualitDelete = await apiRequest(
        "POST",
        "articles-action",
        { id: this.blogRowInfo.id, action: "DESTROY" },
        false
      );
      if (returnActualitDelete && returnActualitDelete.data) {
        this.showDelete = false;
        this.init();
        this.$toast.success("Information supprimée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      this.$v.$touch();
    },


    onSubmit() {
      this.showSave = true;
    },

    onUpdateArticle() {
      this.showEdit = false;
      this.showEditValidation = true
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data">
              <div class="form-group">
                <label for="title">Titre </label>
                <input type="text" id="title" class="form-control" placeholder="Titre de l'blog"
                  v-model="blogObj.title" />
              </div>
              <div class="form-group">
                <label for="tite">Description</label>
                <textarea v-model="blogObj.description" class="form-control" placeholder="Le blog..."></textarea>
              </div>
              <div class="form-group">
                <label for="tite">Contenu</label>
                <ckeditor v-model="blogObj.content" :editor="editor" aria-placeholder="Votre Message" id="editor">
                </ckeditor>

              </div>


              <div class="form-group">
                <label for="file">Image</label>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload" class="form-control" />
              </div>

              <div class="form-group">
                <label for="file">Langue</label>
                <select name="" id="" v-model="blogObj.lang" class="form-control">
                  <option value="FRENCH">Français</option>
                  <option value="ENGLISH">Anglais</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn text-success-save float-right" @click.prevent="onSubmit">
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des blogs actifs</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input v-model="filter" type="search"
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="blogActif" :fields="fields" responsive="sm"
                      :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class=" text-primary" v-b-tooltip.hover @click="editRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                          @click="desactiverRow(row.item)" title="Désactiver" v-if="row.item.visible === true">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-danger" @click="deleteRow(row.item)" v-b-tooltip.hover
                          title="supprimer">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="totalblogActif" :per-page="perPage"
                            :rows="blogActif"></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des blogs inactifs</a>
                  </template>
                  <div class="row  d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="blogDes" :fields="fields" responsive="sm" :per-page="perPage"
                      :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class=" text-primary" v-b-tooltip.hover @click="editRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                          @click="activerblog(row.item)" title="Activer">
                          <i class="mdi mdi-check font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-danger" @click="deleteRow(row.item)" v-b-tooltip.hover
                          title="supprimer">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="totalblogInactif" :per-page="perPage"
                            :rows="blogDes"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment enregister ces informations?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePlan">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showSave" title="Voulez-vous vraiment enregister ces informations?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSave = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="submitblogObj">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showdisable" title="Voulez-vous vraiment désactiver ce bon plan ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivePlan">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showactive" title="Voulez-vous vraiment activer bon plan ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activePlan">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showEditValidation" title="Voulez-vous vraiment modifier l'article?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEditValidation = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="submitblogObjModif">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-center" centered size="xl" v-model="showEdit" title="Editer article" title-class="font-18"
      hide-footer>
      <form enctype="multipart/form-data">
        <div class="form-group">
          <label for="tite">Titre</label>
          <input type="text" id="title" class="form-control" placeholder="titre" v-model="blogObjModif.title" />
        </div>

        <div class="form-group">
          <label for="tite">Description</label>
          <input type="text" id="title" class="form-control" placeholder="sous titre"
            v-model="blogObjModif.description" />
        </div>

        <tiny-mce id="descriptionLong" v-model="descriptionLong" :toolbar="'undo redo'"></tiny-mce>



        <div class="form-group">
          <label for="editor">Contenu</label>
          <ckeditor v-model="blogObjModif.content" :editor="editor" aria-placeholder="Votre Message" id="editor">
          </ckeditor>

        </div>

        <div class="form-group">
          <label for="selector">Langue</label>
          <select name="" id="selector" v-model="blogObjModif.lang" class="form-control">
            <option value="Français">Français</option>
            <option value="Anglais">Anglais</option>
          </select>
        </div>


        <div class="form-group">
          <b-button variant="danger" @click="showEdit = false">Annuler</b-button>
          <button class="btn text-success-save float-right" @click.prevent="onUpdateArticle">Modifier</button>

        </div>


      </form>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}



thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.text-success-save {
  background: #3AAA35;
  outline: none;
  border: none;
  color: #fff;
}

.inputCol {
  position: relative;
}


.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3aaa35;
  border-color: #3aaa35;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}
</style>
